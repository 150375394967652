import React, { Component, useContext } from 'react';
import { getFreelancerDocuments } from '../../Actions/Documents';
import EndpointService from '../../Service/EndpointService';
import { connect } from 'react-redux';
import { List } from 'immutable';
import moment from 'moment';
import PWAContext from '../../PWA/Context/PWAContext';
import './Styles/Documents.scss';

const mapDispatchToProps = (dispatch) => ({
    getFreelancerDocuments: () => dispatch(getFreelancerDocuments())
})

const apiUrl = window.location.href.match('js.local') ? "https://zf.local.perfectstate.nl" : "";

class FreelancerDocuments extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            openedGroup: undefined
        }
    }

    componentDidMount() {
        getFreelancerDocuments && this.loadFreelancerDocuments();
    }

    loadFreelancerDocuments() {
        let { getFreelancerDocuments } = this.props;
        getFreelancerDocuments && getFreelancerDocuments().then((documents) => {
            this.setState({
                documents: documents,
                loaded: true
            })
        })
    }

    toggleGroup(index) {
        this.setState((state) => {
            return {
                ...state,
                openedGroup: state.openedGroup == index ? undefined : index
            }
        })
    }

    render() {
        let { documents, openedGroup } = this.state;

        console.log("structure of documents", documents);

        documents = (documents || List()).sort((a,b) => {
            if(a.get('typeid') != b.get('typeid')) {
                return a.get('typeid') - b.get('typeid');
            }
            return Number(b.get('created')) - Number(a.get('created'));
        });

        // let grouped = documents.groupBy(x => x.get('typeid'));
        let grouped = documents.groupBy(x => x.getIn(['type','metadescriptors','folder']))

        console.log("grouped", grouped);

        // document.getIn(['type', 'metadescriptors', 'folder'])

        return <div className="card">

            <div className="card-header">
                <h1>Mijn Documenten</h1>

            </div>

            <div className="card-body" style={{padding:"20px"}}>
            
            
                <div className="documents document-table">



                    {grouped.map((documents, index) => {


                        return <div className="document-table-group" key={(index || 0).toString()}>

                           
                            <div className="document-table-group-header" onClick={this.toggleGroup.bind(this, index)}>
                                {documents.first().getIn(['type', 'metadescriptors', 'folder'])}
                                <div style={{"float": "right","fontSize":"15px"}}>{ openedGroup == index ? <i className="zmdi zmdi-chevron-down" /> : <i className="zmdi zmdi-chevron-right" /> }</div>
                            </div>
                            { openedGroup == index && <div className="document-table-group-body">
                                 {(documents || List()).map((document) => {

                                    return <Document document={document} key={document.get('id').toString()} />


                                   

                                })}
                            </div> }

                           

                        </div>

                    }).toList()}

                    

                    

                </div>

            </div>

        </div>
    }


}


export async function fetchDocument(url) {

    let response = await EndpointService.get(url, { responseType: 'blob' });
    return response



}

function DocumentButton({ children, url }) {

    const { isPWA } = useContext(PWAContext);


    if(!isPWA) {
        return <a href={apiUrl + url} target="_blank">{children}</a>
    }

    const onClick = async () => {
        if(!url) {
            return;
        }
        const response = await fetchDocument(url);
        const blobUrl = URL.createObjectURL(response.data);
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = url.split('/').pop();
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(blobUrl);
    }

    return <a onClick={onClick}>{children}</a>

}

function Document({ document }) {

    let files = document.get('files', List()) || List();
    let firstFile = files.first();
    let firstFileName = firstFile && files.keySeq().first();

    let displayNameOrNothing = (document) => document.get('name') ? `(${document.get('name')})` : '';

    return <div className="document-table-row">

        <div className="document-table-item">
            <div className="document-table-item-content">
                { files.size == 1 && <DocumentButton url={`/freelancerportal/files/freelancers/${document.get('entityid')}/${document.get('id')}/${firstFileName}`}>
                    {document.getIn(['type','name'])} {displayNameOrNothing(document)}
                </DocumentButton> || <a>{document.getIn(['type','name'])} {displayNameOrNothing(document)}</a> }
                
                { files.size > 1 && <ul>
                    {(document.get('files') || []).map((file, filename) => {
                        return (
                            <li key={filename} style={{paddingLeft:'20px'}}>
                                <DocumentButton url={`/freelancerportal/files/freelancers/${document.get('entityid')}/${document.get('id')}/${filename}`}>{filename}</DocumentButton>
                            </li>
                        )
                    }).toList()}

                    </ul>
                }
            </div>
        </div>
     </div>

}

export default connect(null, mapDispatchToProps)(FreelancerDocuments);