import { useEffect, useState, Fragment } from 'react';
import EndpointService from '../Service/EndpointService';

async function getQRCode() {
    let statusResponse = await EndpointService.post('/api/generate-qr-code', {});
    return statusResponse.data;
}

async function checkQRCode(token) {
    let statusResponse = await EndpointService.post('/api/generate-qr-check', { token });
    return statusResponse.data;
}


let steps = [
    'start-or-skip-registration',
    'start-registration',
    'scan-qr-code',
    'confirm-qr-code',
    'click-on-link-in-email'
]

let stepComponents = [
    StartOrSkip2FARegistration,
    Start2FARegistrationMessage,
    ScanQRCodeStep,
    ConfirmQRCodeStep,
    ClickOnLinkInEmail
]

export default function SecondFactorRegistrationPanel({ renewLoginStatus }) {

    const [step, setStep] = useState('start-or-skip-registration');
    const [registrationState, _setRegistrationState] = useState({});

    const setRegistrationState = (newState) => {
        _setRegistrationState({ ...registrationState, ...newState });
    }

    const StepComponent = stepComponents[steps.indexOf(step)];

    const onNext = (registrationState) => {
        let currentStep = steps.indexOf(step);
        let nextStep = (currentStep + 1) % steps.length;
        setStep(steps[nextStep]);
    }

    const onPrevious = (registrationState) => {
        let currentStep = steps.indexOf(step);
        let previousStep = (currentStep - 1) % steps.length;
        setStep(steps[previousStep]);
    }

    const onFinish = (registrationState) => {

    }

    return (
        <StepComponent renewLoginStatus={renewLoginStatus} onNext={onNext} onPrevious={onPrevious} onFinish={onFinish} registrationState={registrationState} />
    );
}

// Step 1 - Start or skip 2fa registration
function StartOrSkip2FARegistration({ onNext, onFinish, registrationState }) {

    const expirationDate = new Date().toLocaleDateString();

    return <div className="flex flex-col gap-3">
         <h1 className="font-bold">2 factor authenticatie activeren</h1>
        <p>
            Om aan de eisen van de Algemene verordening gegevensbescherming (AVG) te voldoen wordt een extra beveiliging ingevoerd in de vorm van ‘2 Factor authenticatie’.
            Dit betekent dat u bij het inloggen op het portaal van U Staat Centraal (Zorgconnect) uw telefoon bij de hand dient te houden om via de gratis App ‘google authenticator’ een verspringende code in te voeren.
            Hiervoor dient deze app gekoppeld te worden aan uw account.
        </p>
        <p>
            U heeft nog geen 2 factor authenticatie ingeschakeld, wij verzoeken u dit voor [[expirationDate]] in te schakelen.
        </p>
        <p>
            Wilt u 2 factor authenticatie nu inschakelen klik dan op volgende en houdt uw telefoon bij de hand. Voer dit proces bij voorkeur uit op een computer
        </p>
        <div className="flex flex-row gap-3 w-full">
            <button className="flex-1 px-3 py-2 bg-red-500 text-white" onClick={() => onFinish({ skipped: true })}>Overslaan</button>
            <button className="flex-1 px-3 py-2 bg-green-500 text-white" onClick={() => onNext()}>Volgende</button>
        </div>
    </div>
}

// Step 2 - Starting the 2FA registration process
function Start2FARegistrationMessage({ onNext, onPrevious, registrationState }) {
    return <div className="flex flex-col gap-3">
        <h1 className="font-bold">Download google authenticator</h1>

        <div className="flex flex-row justify-center">
            <img src="https://zp.local.perfectstate.nl/img/google-authenticator.webp" />

        </div>
        <p>
            Download nu in de app store van uw telefoon de applicatie genaamd ‘google authenticator’ en volg daarin de 
            stappen (zorg ervoor dat het icoontje overeenkomt met de bovenstaande plaatje), klik op volgende 
            als u dit gedaan heeft.
        </p>
        <div className="flex flex-row gap-3 w-full">
         
            <button className="flex-1 px-3 py-2 bg-green-500 text-white" onClick={() => onNext()}>Volgende</button>
        </div>
    </div>
}

function ScanQRCodeStep({ onNext, onPrevious, registrationState }) {

    const [qrcodeimage, setQRCodeImage] = useState('');

    useEffect(() => {
        (async () => {
            let {qrCodeImage} = await getQRCode();
            setQRCodeImage(qrCodeImage);
        })();
    }, []);


    return <div className="flex flex-col gap-3">
        { !qrcodeimage && <p>Bezig met laden...</p> }
        { qrcodeimage && <Fragment>
            <h1>Scan onderstaande code</h1>
            <div className="flex flex-row justify-center">
                <img src={qrcodeimage} />
            </div>
            <p>
                Scan nu met de camera van uw telefoon de bovenstaande qr code. En klik op volgende als u dit heeft gedaan.
            </p>
            <div className="flex flex-row gap-3 w-full">
                
                <button className="flex-1 px-3 py-2 bg-green-500 text-white" onClick={() => onNext()}>Volgende</button>
            </div>
        </Fragment> }
    </div>
}

function ConfirmQRCodeStep({ onNext, onPrevious, registrationState }) {

    const [token, setToken] = useState('');
    const [error, setError] = useState('');

    const checkToken = async () => {
        let { success, error } = await checkQRCode(token);
        if(success) {
            onNext();
        } else {
            setError(error);
        }
    }

    return <div className="flex flex-col gap-3">
        <h1 className="font-bold">Voer het nummer in</h1>
        <p>
            Voer nu hieronder het nummer in die u ziet verschijnen in uw google authenticator app op uw telefoon, naast het nummer ziet u een timer die aangeeft hoeveel tijd u heeft om dit te doen. Heeft u weinig tijd, wacht dan tot het volgende nummer verschijnt.
        </p>
        {error && <div className="bg-red-500 text-white p-2"> {error} </div> || null}
        <div method="post" className="flex flex-col gap-3">
            <div class="form">
                <div class="control">
                    <div class="label">
                        <label>Code (Google authenticator):</label>
                    </div>
                    <div class="input">
                        <input type="number" autoFocus={true} name="token" value={token} onChange={(e) => { setToken(e.target.value) }} />
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-3 w-full">
                <button className="flex-1 px-3 py-2 bg-green-500 text-white" onClick={() => checkToken(token)}>Volgende</button>
            </div>
        </div>
    </div>
}

function ClickOnLinkInEmail({ onNext, onPrevious, onFinish, registrationState, renewLoginStatus }) {




    return <div>
        <h1>De code is correct!</h1>
        <p>
            We hebben uw telefoon kunnen registeren. U ontvangt binnen enkele minuten ter bevestiging een e-mail met daarin een link. Klikt u op die link om het proces af te ronden.
        </p>
        <div className="flex flex-row gap-3 w-full">
            <button className="flex-1 px-3 py-2 bg-green-500 text-white" onClick={() => renewLoginStatus()}>Vernieuwen</button>
        </div>
    </div>
}

/*
    {"set2FA": {"private2FA": "LNDTMZSXOI6HQ7KXJBFCCV3YGAZXCWTRHF6SU6ZQGRHFGLSQHRQQ", "require2FA": true, "keySettings": {"type": "google", "seconds": 30, "encoding": "base32"}, "activationPin": 9120}, "private2FA": "LNDTMZSXOI6HQ7KXJBFCCV3YGAZXCWTRHF6SU6ZQGRHFGLSQHRQQ", "require2FA": true, "keySettings": {"type": "google", "seconds": 30, "encoding": "base32"}} | {"generalToken": "55e95ca88f5101ea319dab94b1343cbbaec8d2f9de7c86ec65c3bd799039d2a4"}
*/